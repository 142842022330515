
function surgeSitePreloaderOn() {
    // Do something
    TweenMax.to($(".bar_preloader"), 3.0, {
        width: "100%",
        ease: Power4.easeOut,
    });
    TweenMax.to($("#the_page"), 3.0, {
      autoAlpha: "0"
    });
}

  function surgeSitePreloaderOff() {
    // Do something
    TweenMax.to($(".bar_preloader"), 3.0, {
        width: "0%"
    });
    TweenMax.to($("#the_page"), 3.0, {
      autoAlpha: "1"
    });
}