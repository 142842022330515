/* woo cat product tiles */
$(window).load(function() {
    var offset_top,
        group = 0,
        groups = [];
    $.each($("ul.products li"), function(i, e) {
        if (i === 0) {
            offset_top = $(this).offset().top;
            $(this).attr("data-group", group);
            groups.push({ "group_id": group });
        } else {

            var new_offset_top = $(this).offset().top;
            if (offset_top === new_offset_top) {
                $(this).attr("data-group", group);
            } else {
                offset_top = new_offset_top;
                group++;
                $(this).attr("data-group", group);
                groups.push({ "group_id": group });
            }
        }
    });

    var get_tallest = function(el) {
        var elementHeights = $(el).map(function() {
            return $(this).outerHeight(true);
        }).get();
        //console.log(elementHeights);
        // Math.max takes a variable number of arguments
        // `apply` is equivalent to passing each height as an argument
        var maxHeight = Math.max.apply(null, elementHeights);
        return maxHeight;
    };

    $.each(groups, function(i, e) {
        var tallest_image = get_tallest($("ul.products li[data-group='" + i + "'] img"));
        $.each($("ul.products li[data-group='" + i + "'] img"), function(i, e) {
            var this_image_height = $(this).outerHeight(true);
            //console.log("this_image_height:" + this_image_height);
            //console.log("tallest_image:" + tallest_image);
            if (this_image_height !== tallest_image) {
                var image_margin = (tallest_image - this_image_height) / 2;
                var image_margin_bottom = image_margin + 16;
                //console.log("image_margin: " + image_margin);
                //console.log("image_margin_bottom: " + image_margin_bottom);
                $(this).css("margin-top", image_margin + "px");
                $(this).css("margin-bottom", image_margin_bottom + "px");
            }
        });

        var el = $("ul.products li[data-group='" + i + "'] h2");

        // Get an array of all element heights
        var elementHeights = $(el).map(function() {
            return $(this).outerHeight(true);
        }).get();
        //console.log(elementHeights);
        // Math.max takes a variable number of arguments
        // `apply` is equivalent to passing each height as an argument
        var maxHeight = Math.max.apply(null, elementHeights);

        // Set each height to the max height

        $(el).height(maxHeight);
    });
});