// Side Menu 
var menuLeft = document.getElementById('cbp-spmenu-s1'),
  menuRight = document.getElementById('cbp-spmenu-s2'),
  menuTop = document.getElementById('cbp-spmenu-s3'),
  menuBottom = document.getElementById('cbp-spmenu-s4'),
  showLeft = document.getElementById('showLeft'),
  showRight = document.getElementById('showRight'),
  showTop = document.getElementById('showTop'),
  showBottom = document.getElementById('showBottom'),
  showLeftPush1 = document.getElementById('showLeftPush1'),
  showLeftPush2 = document.getElementById('showLeftPush2'),
  showRightPush = document.getElementById('showRightPush'),
  body = document.body;

// showLeft.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuLeft, 'cbp-spmenu-open' );
//   disableOther( 'showLeft' );
// };
// showRight.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuRight, 'cbp-spmenu-open' );
//   disableOther( 'showRight' );
// };
// showTop.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuTop, 'cbp-spmenu-open' );
//   disableOther( 'showTop' );
// };
// showBottom.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuBottom, 'cbp-spmenu-open' );
//   disableOther( 'showBottom' );
// };

showLeftPush1.onclick = function () {
  classie.toggle(this, 'active');
  classie.toggle(body, 'cbp-spmenu-push-toright');
  classie.toggle(menuLeft, 'cbp-spmenu-open');
  // disableOther( 'showLeftPush' );
  //$("#showLeftPush1").css("display", "none");
  TweenMax.to($("#showLeftPush1"), 0.5, { // Add tweenmax.
    top: "-60px"
  });
};

showLeftPush2.onclick = function () {
  classie.toggle(this, 'active');
  classie.toggle(body, 'cbp-spmenu-push-toright');
  classie.toggle(menuLeft, 'cbp-spmenu-open');
  // disableOther( 'showLeftPush' );
  //$("#showLeftPush1").css("display", "block");
  TweenMax.to($("#showLeftPush1"), 0.5, { // Add tweenmax.
    top: "0px",
    ease: Elastic.easeOut.config(3, 0.5)
  });
};

// showRightPush.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( body, 'cbp-spmenu-push-toleft' );
//   classie.toggle( menuRight, 'cbp-spmenu-open' );
//   disableOther( 'showRightPush' );
// };

// function disableOther( button ) {
//   if( button !== 'showLeft' ) {
//     classie.toggle( showLeft, 'disabled' );
//   }
//   if( button !== 'showRight' ) {
//     classie.toggle( showRight, 'disabled' );
//   }
//   if( button !== 'showTop' ) {
//     classie.toggle( showTop, 'disabled' );
//   }
//   if( button !== 'showBottom' ) {
//     classie.toggle( showBottom, 'disabled' );
//   }
//   if( button !== 'showLeftPush' ) {
//     classie.toggle( showLeftPush, 'disabled' );
//   }
//   if( button !== 'showRightPush' ) {
//     classie.toggle( showRightPush, 'disabled' );
//   }
// }

// Nav Menu Burger Menu

// $('.nav-icon1').click(function() {
//   $('.main_nav').toggleClass('menuOpen');
//   $(this).toggleClass('open');
// });

// Nav Drop Down
$('li.is_dropdown.parent_li').hover(function () {
  $(".mega_dropdown").css("opacity", "0");
  $(this).find(".mega_dropdown").css("opacity", "1");
  var tl = new TimelineMax();
  TweenLite.killTweensOf(".mega_dropdown > li");
  TweenLite.set(".mega_dropdown > li", {
    clearProps: "all"
  });
  tl.from('.mega_dropdown', .25, {
    ease: Power2.easeOut,
    transformOrigin: "top",
    rotationY: 90
  })
  tl.staggerFrom('.mega_dropdown > li', .2, {
    ease: Power1.easeIn,
    y: -20,
    opacity: 0
  }, .015);
  // tl.staggerFrom('.mega_dropdown > li a', .2, {ease: Power1.easeIn, opacity: 0, x: -60}, .01); 
}, function () {
  $(".mega_dropdown").css("opacity", "0");
});