// remove link on logo if its the home page
if (window.location.href === site_url) {
  $("a.logo_link").attr("href", "#");
} else {
  $("a.logo_link").attr("href", site_url);
}

// make these images responsive:
// blog post page images
$(".entry-content img").addClass("img-responsive");


/* cookie policy */
$(document).ready(function () {
  setTimeout(function () {
    TweenMax.to("#the_page, #munchMyCookies", 0.25, {
      opacity: 1.0
    });
  }, 300);
  if ($.cookie("acceptsCookies") === "yes") {
    $(".exitMunchMyCookies").closest("#munchMyCookies").hide();
  } else {
    // setTimeout(function() {
    //     TweenMax.set("#the_page .page_header", {
    //         marginTop: 115
    //     });        
    // }, 300);
    $(".exitMunchMyCookies").click(function () {
      $.cookie("acceptsCookies", "yes", {
        path: '/'
      });
      $(this).closest("#munchMyCookies").fadeOut(900);
      TweenMax.to("#the_page .page_header", 0.25, {
        marginTop: 30,
        delay: 0.9
      });
    });
  }
});
// document ready
/* END cookie policy */


// center things
/*TweenMax.set(".center_me", {
    xPercent: -50,
    yPercent: -50
});

TweenMax.set(".center_me_vertically", {
    yPercent: -50
});

TweenMax.set(".center_me_horizontally", {
    xPercent: -50
});*/

// if (Modernizr.mq('(max-width: 1070px)')) {
//     TweenMax.set(".center_me_1070", {
//         xPercent: -50,
//         yPercent: -50
//     });
// }

var do_centering = function () {
  if (Modernizr.mq('(max-width: 991px)')) {
    TweenMax.set(".mobile_center_xPercent", {
      xPercent: -50,
      left: "50%"
    });
  } else {
    TweenMax.set(".mobile_center_xPercent", {
      clearProps: "xPercent, left"
    });
  }
};

$(window).resize(function() {
  do_centering();
});

do_centering();