var scroll_animation;
function goToByScroll(location, offset_top, speed) {
    if (typeof scroll_animation !== "undefined") {
        scroll_animation.kill();
    }
    scroll_animation = new TimelineMax();
    scroll_animation.to($(window), speed, {
        scrollTo: {
            y: location,
            offsetY: offset_top,
            autoKill: true
        },
        ease: Power3.easeOut
    });
}
