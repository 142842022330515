var pop_over_box,
    pop_over_box_width,
    document_width_for_pop_over = $(window).width(),
    site_url = MyAjax.ajaxurl.replace("wp-admin/admin-ajax.php", ""),
    window_height = $(window).height(),
    event_type = function() {
        if ('onpointerdown' in window) {
            // use 'pointerdown' if pointerEvent API is supported
            clickEvent = 'pointerdown';
        } else if ('ontouchstart' in window) {
            // use 'touchstart' if touch device
            clickEvent = 'touchstart';
        } else {
            // else use mouse event
            clickEvent = 'click';
        }
        return clickEvent;
    },
    interval_activeElement,
    interval_height,
    mobile_tests_timeout,
    height_timeout,
    check_timeout,
    check_interval,
    new_scroll_top,
    new_popup_height,
    dif_height = 0,
    timeout_focusout,
    pop_over_box_move = function() {
        TweenMax.to("#grey_page_cover", 0.5, { top: $(window).scrollTop() });
        $("body, html").css("overflow", "hidden");
    },
    do_popup_layout = function() {
        //pop_up_remove_me glyphicon glyphicon-remove
        if (!$("#grey_page_cover").length) {
            $("body").append('<div id="grey_page_cover"><div id="pop_over_box"><div class="pop_up_remove_me"></div><div class="popup_content_here"></div></div></div>');
        }
        pop_over_box = $("#pop_over_box");

        if ($(window).width() >= 1140) {
            pop_over_box_width = "62%";
        } else if ($(window).width() >= 700) {
            pop_over_box_width = "90%";
        } else {
            pop_over_box_width = "95%";
        }

        TweenMax.set($("#grey_page_cover"), {
            position: "absolute",
            top: "0",
            height: $(window).innerHeight() + "px",
            width: "100%",
            backgroundImage: "url('" + site_url + "wp-content/themes/sage-8.4.2/dist/images/landing_gradient_bg.jpg')",
        });
        TweenMax.set($(".popup_content_here", pop_over_box), {
            "overflow-y": "auto",
            "position": "relative",
            height: "100%"
        });
        TweenMax.set(pop_over_box, { // background
            position: "fixed",
            textAlign: "left",
            top: "0%",
            left: "50%",
            height: "100%",
            width: "100%",
            zIndex: 999,
            backgroundImage: "url('" + site_url + "wp-content/themes/sage-8.4.2/dist/images/landing_gradient_bg.jpg')",
            padding: "1.5% 1%",
            xPercent: -50
        });
    },
    set_up_pop_over_box = function() {
        /*/
    //////////////////////////////////////
    //reposition on active field / textarea
    $(document).on(event_type()+".reposition", ".popup_content_here input, .popup_content_here textarea", function(e) {
      event = e;

      event.preventDefault(); // the important thing I think
      event.stopPropagation();
      var scroll_position = $(".popup_content_here").scrollTop(),
          popup_height = $(".popup_content_here").height();
      //alert(scroll_position);
      //alert(popup_height);
      $(this).click().focus();
      var that = this;
      // setTimeout(function(){
      //   if (typeof $(document.activeElement).attr('type') !== 'undefined') { // clear out those ghosts
      //     if($(document.activeElement).attr('type') === "text") {
      //       new_scroll_top = ($(document.activeElement).offset().top + $(document.activeElement).outerHeight(true)) - $(".popup_content_here").offset().top;
      //     }
      //   }
      // }, 1200);


      clearTimeout(check_timeout);
      check_timeout = null;

      check_timeout = setTimeout(function(){
        if ($(document.activeElement).is('input') || $(document.activeElement).is('textarea')) { // clear out those ghosts
          //console.log(($(document.activeElement).offset().top + $(document.activeElement).outerHeight(true)) - $(".popup_content_here").offset().top);
          //+ parseInt($(".popup_content_here").css("padding-top"), 10)


          //$(".popup_content_here").scrollTop(scroll_position);
          //var new_popup_height = $(".popup_content_here").height();

          new_popup_height = $(".popup_content_here").height();
          dif_height = popup_height - new_popup_height;
          //alert("dif_height: "+dif_height);
          if (dif_height > 0) {
            // good browsers
            new_scroll_top = ($(document.activeElement).offset().top + $(document.activeElement).outerHeight(true)) - ($(".popup_content_here").offset().top - dif_height);
            $(".popup_content_here").scrollTop(new_scroll_top);
          } else {
            // iphone
            new_scroll_top = ($(document.activeElement).offset().top + $(document.activeElement).outerHeight(true)) - $(".popup_content_here").offset().top;
          }

          //alert(new_scroll_top);


        }
      }, 1700);

      $(that).focusout(function(e) {
        //alert(new_scroll_top);
        timeout_focusout = setTimeout(function(){
          //if (typeof $(document.activeElement).attr('type') !== 'undefined') { // clear out those ghosts
            //if($(document.activeElement).attr('type') !== "text") {
              //clearTimeout(check_timeout);
              //check_timeout = null;
              new_scroll_top = ($(document.activeElement).offset().top + $(document.activeElement).outerHeight(true)) - $(".popup_content_here").offset().top;
              new_popup_height = $(".popup_content_here").height();
              dif_height = popup_height - new_popup_height;
              if (dif_height > 0) {

              } else {
                alert("else");
                $(".popup_content_here").scrollTop(new_scroll_top);
              }

            //}
          //}
        }, 1200);
      });


      // $(that).on("keypress", function(e){
      //   setTimeout(function(){
      //     if(e.which === 13){
      //       alert(console.log(($(document.activeElement).offset().top + $(document.activeElement).outerHeight(true)) - $(".popup_content_here").offset().top));
      //       $(".popup_content_here").scrollTop(($(document.activeElement).offset().top + $(document.activeElement).outerHeight(true)) - $(".popup_content_here").offset().top);
      //       clearInterval(check_interval);
      //       check_interval = null;
      //     }
      //   }, 1200);
      // });


      // clearTimeout(check_timeout);
      // var check_timeout = setTimeout(function(){
      //     $(".popup_content_here").scrollTop(($(that).offset().top + $(that).outerHeight(true)) - $(".popup_content_here").offset().top);
      //
      //     clearInterval(check_interval);
      //     check_interval = null;
      //
      //     check_interval = setInterval(function() {
      //       // var new_scroll_position = $(".popup_content_here").scrollTop(),
      //       //     new_popup_height = $(".popup_content_here").height();
      //       //
      //       // if (new_popup_height === popup_height) {
      //       //   clearInterval(check_timeout);
      //       //   check_timeout = null;
      //       //   //alert("same");
      //       // }
      //
      //       if (typeof $(document.activeElement).attr('type') !== 'undefined') { // clear out those ghosts
      //         if($(document.activeElement).attr('type') !== "text"){
      //           clearInterval(check_interval);
      //           check_interval = null;
      //           $(".popup_content_here").scrollTop(scroll_position);
      //           alert(scroll_position);
      //         }
      //       }
      //     }, 200);
      //
      // }, 2500);


    });

/*/
        // END reposition on active field / textarea
        //////////////////////////////////////

        $(window).on("scroll.popover_scroll", pop_over_box_move);
        $(window).on("resize.popover_resize", function() {
            if (document_width_for_pop_over !== $(window).width()) { // resize / scroll was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening
                document_width_for_pop_over = $(window).width();
                set_up_pop_over_box();
            }
        });
        do_popup_layout();
        pop_over_box_move();
    },
    popup_set_html = function(the_popup_html) {
        set_up_pop_over_box();
        $(".popup_content_here", pop_over_box).html(the_popup_html);
    },
    remove_the_popup = function() {
        //  $(document).off(event_type()+".reposition");
        $(window).off("scroll.popover_scroll");
        $(window).off("resize.popover_resize");
        $("body, html").css("overflow", "auto");

        $(".content_for_popup").hide();
        $(".desktop_logins_row").after($(".content_for_popup"));

        $("#grey_page_cover").remove();
    };
$(document).on("click", ".pop_up_remove_me, #grey_page_cover", function(e) {
  // stop child elements triggering it
  if (e.target !== e.currentTarget) { // http://stackoverflow.com/a/36876862/2129024
    return;
  }
  remove_the_popup();
});

// how to use this...
// pass html into the popup with popup_set_html("<p>Testing 123</p>");
// then use remove_the_popup() to manually close it;
